import { useState, useEffect } from 'react';

/**
 * 1つ以上のメニュー要素のうち常に1つの要素だけが開いている状態を作るためのフック
 *
 * @param {string} menuClassName 1つ以上のメニュー要素に共通して一番上位に存在するHTMLElementに追加されているクラス名
 * @returns {Object} result
 * @returns {function(name: string): boolean} result.isOpen nameにはメニュー要素を識別する値を指定する。メニュー要素の開閉状態を判定する
 * @returns {function(name: string): void} result.toggleMenu nameにはメニュー要素を識別する値を指定する。メニュー要素の開閉のトグルを行う
 */
export const useToggleMenu = (menuClassName: string) => {
  const [openedMenuName, setOpenedMenuName] = useState<string | null>(null);
  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (
        e.target instanceof HTMLElement &&
        !e.target.closest('.' + menuClassName)
      ) {
        setOpenedMenuName(null);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [menuClassName]);

  const isOpen = (name: string) => openedMenuName === name;

  const toggleMenu = (name: string) =>
    setOpenedMenuName(isOpen(name) ? null : name);

  return { isOpen, toggleMenu };
};
