import React from 'react';
import header from '!!raw-loader!js/tag/advertisement/pc/nontop/header.php';
import { PrBanner } from 'js/components/ui/PrBanner';

export type Props = {
  isAdDisplayed: boolean;
  isSimpleHeader: boolean;
  globalHeaderBanner?: string;
};

export const HeaderBannerPresenter: React.FC<Props> = ({
  isAdDisplayed,
  isSimpleHeader,
  globalHeaderBanner,
}) => {
  const banner = globalHeaderBanner ?? header;
  if (isAdDisplayed && banner !== 'none' && !isSimpleHeader) {
    return (
      <PrBanner
        size="728x90"
        adInnerHtml={banner}
        style={{ margin: '20px auto' }}
      />
    );
  }
  return (
    <div className="global-header-banner__none" style={{ height: '20px' }} />
  );
};
