import React, { Dispatch, SetStateAction, useEffect } from 'react';
import classNames from 'classnames';
import {
  MyMenuWithFavoriteWindow,
  Props as MyMenuWithFavoriteWindowProps,
} from 'js/components/common/layout/MyMenu/MyMenuWithFavoriteWindow';
import { AffiliateItemType } from 'js/types/affiliate';
import { useToggleMenu } from 'js/hooks/useToggleMenu';
import { useReadyWhenShow } from 'js/hooks/useReadyWhenShow';

export const myMenuClassName = 'my-menu';

export type Props = Omit<
  MyMenuWithFavoriteWindowProps,
  'isShow' | 'favorites'
> & {
  favoriteItems: AffiliateItemType[];
  setIsReady: Dispatch<SetStateAction<boolean>>;
};

export const WideMyMenuPresenter: React.FC<Props> = ({
  myMenuPoint,
  shortcuts,
  favoriteItems,
  setIsReady,
}) => {
  const { isOpen, toggleMenu } = useToggleMenu(myMenuClassName);
  const { isReady } = useReadyWhenShow(isOpen(myMenuClassName));

  useEffect(() => {
    setIsReady(isReady);
  }, [isReady, setIsReady]);

  return (
    <nav
      className={classNames(myMenuClassName, {
        'is-open': isOpen(myMenuClassName),
      })}
    >
      <button
        className="my-menu__button c_reset"
        type="button"
        onClick={() => toggleMenu(myMenuClassName)}
      >
        マイメニュー
      </button>
      <MyMenuWithFavoriteWindow
        isShow={isOpen(myMenuClassName)}
        {...{ myMenuPoint, shortcuts }}
        favorites={favoriteItems}
      />
    </nav>
  );
};
