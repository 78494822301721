import { Props as WideMyMenuProps } from 'js/components/common/layout/MyMenu/WideMyMenu';
import { useUserInfo } from 'js/hooks/useUserInfo';

export const useMyMenuProps = () => {
  const { userInfo } = useUserInfo();
  const myMenuProps: Omit<WideMyMenuProps, 'setIsReady'> | undefined =
    userInfo && {
      myMenuPoint: {
        totalPoint: userInfo.totalPoint,
        expiringPoint: userInfo.pointLimit.limitPoint,
        expirationDate: userInfo.pointLimit.nextLimitDate,
      },
      shortcuts: [
        {
          icon: 'information',
          name: 'お知らせ',
          href: '/information/importance/',
        },
        {
          icon: 'mypage',
          name: 'マイページ',
          href: '/mypage/',
        },
        {
          icon: 'history',
          name: 'サービス\n利用履歴',
          href: '/mypage/affiliate_history/',
        },
        {
          icon: 'history',
          name: 'ギフト獲得\n履歴',
          href: '/mypage/history/gift/list/',
        },
        {
          icon: 'help',
          name: 'ヘルプ',
          href: '/help/',
        },
        {
          icon: 'guide',
          name: 'ガイド',
          href: '/guide/',
        },
        {
          icon: 'guide',
          name: 'メンバーズ\nクラブガイド',
          href: '/members_club/',
        },
        {
          icon: 'logout',
          name: 'ログアウト',
          href: '/logout/',
          callback: (e) => {
            if (!confirm('ログアウトしますか？')) {
              e.preventDefault();
            }
          },
        },
      ],
    };
  return { myMenuProps };
};
