import React from 'react';
import classNames from 'classnames';
import { SecondaryContentsItemType } from 'js/components/common/layout/Header/SecondaryContentsList/type';

export type Props = {
  list: SecondaryContentsItemType[];
  column?: 2 | 3;
};

export const SecondaryContentsListPresenter: React.FC<Props> = ({
  list,
  column = 2,
}) => (
  <ul className={classNames('secondary-contents__list', `column-${column}`)}>
    {list.map((item) => (
      <li className="secondary-contents__item" key={item.name}>
        <a
          className={classNames('secondary-contents__link', 'c_blocklink', {
            'has-notification': item.hasNotification,
          })}
          href={item.itemUrl}
          title={item.title}
          {...(item.isTargetBlank ? { target: '_blank', rel: 'noopener' } : {})}
        >
          {item.name}
        </a>
      </li>
    ))}
  </ul>
);
