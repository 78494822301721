import React from 'react';
import {
  SecondaryContentsListPresenter,
  Props as PresenterProps,
} from 'js/components/common/layout/Header/SecondaryContentsList/presenter';

export type Props = PresenterProps;

export const SecondaryContentsList: React.FC<Props> = ({ list, column }) => (
  <SecondaryContentsListPresenter list={list} column={column} />
);
