import { useEffect, useState } from 'react';
import { useUserInfo } from 'js/hooks/useUserInfo';
import Cookies from 'js-cookie';

export const useIsLoginFromCookie = () => {
  const [isLogin, setIsLogin] = useState<boolean | undefined>();
  const { isLogin: isLoginFromAPI, isLoading } = useUserInfo();

  useEffect(() => {
    if (Cookies.get('IS_USER_LOGIN')) {
      setIsLogin(true);
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setIsLogin(isLoginFromAPI);
    }
  }, [isLoading, isLoginFromAPI]);

  return { isLogin };
};
