import { useRequest } from 'js/hooks/useRequest';

export interface GlobalHeaderApiTaskalertCountResponse {
  count: string;
}

export const useDailyContentsCount = () => {
  const { data } = useRequest<GlobalHeaderApiTaskalertCountResponse>(
    '/global/header/api/taskalert/count/',
  );

  return { dailyContentsCount: data ? Number(data.count) : 0 };
};
