import { useRequestImmutable } from 'js/hooks/useRequest';
import { DailyContentsItemWithKey } from 'js/components/common/layout/DailyContents/DailyContentsList/type';

export interface GlobalHeaderApiTaskalertTaskResponse {
  count: string;
  description: string;
  ga_label: string;
  icon: string;
  link: string;
  target_blank?: string;
  title: string;
}

export const useDailyContents = (isReady: boolean) => {
  const { data } = useRequestImmutable<GlobalHeaderApiTaskalertTaskResponse[]>(
    isReady ? ['/global/header/api/taskalert/task/'] : null,
  );
  const dailyContents = data
    ? data.map<DailyContentsItemWithKey>((content) => ({
        key: content.title,
        itemUrl: content.link,
        imageUrl: `/global/icon/daily_contents/${content.icon}`,
        name: content.title,
        title: content.description,
        hasNotification: !!Number(content.count),
        isTargetBlank: content.target_blank === '1',
      }))
    : [];
  return { dailyContents };
};
