import React from 'react';
import {
  WideGlobalFooterLinksPresenter,
  Props as PresenterProps,
} from 'js/components/common/layout/Footer/WideGlobalFooterLinks/presenter';

export type Props = PresenterProps;

export const WideGlobalFooterLinks: React.FC<Props> = ({ isLogin }) => (
  <WideGlobalFooterLinksPresenter isLogin={isLogin} />
);
