import React from 'react';
import { AffiliateSimpleList } from 'js/components/common/Affiliate/AffiliateSimpleList';
import { FavoriteNone } from 'js/components/common/layout/Favorite/FavoriteNone';
import { Props as AffiliateSimpleItemProps } from 'js/components/common/Affiliate/AffiliateSimpleItem';

export type Props = {
  list?: AffiliateSimpleItemProps[];
};

export const MyMenuFavoritePresenter: React.FC<Props> = ({ list }) => (
  <section className="my-menu-favorite my-menu__unit">
    <div className="my-menu-favorite__heading">お気に入り</div>
    {!!list?.length ? (
      <AffiliateSimpleList {...{ list }} isBannerOnly={true} column={3} />
    ) : (
      <FavoriteNone className="my-menu-favorite__none" />
    )}
  </section>
);
