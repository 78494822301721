import React from 'react';

export type Props = {
  heading: string;
  children: React.ReactNode;
};

export const SecondaryContentsPresenter: React.FC<Props> = ({
  heading,
  children,
}) => (
  <>
    <p className="secondary-contents__heading">{heading}</p>
    {children}
  </>
);
