import React from 'react';
import { DailyContentsSecondaryWindowPresenter } from 'js/components/common/layout/DailyContents/DailyContentsSecondaryWindow/presenter';
import { useUserInfo } from 'js/hooks/useUserInfo';
import { useReadyWhenShow } from 'js/hooks/useReadyWhenShow';
import { useDailyContents } from 'js/components/common/layout/Header/WideGlobalHeader/hooks/useDailyContents';

export type Props = {
  isOpen: boolean;
};

export const DailyContentsSecondaryWindow: React.FC<Props> = ({ isOpen }) => {
  const { isLogin } = useUserInfo();
  const { isReady } = useReadyWhenShow(isOpen);
  const { dailyContents } = useDailyContents(isReady);
  const playedCount = dailyContents.reduce(
    (sum, content) => sum + (content.hasNotification ? 0 : 1),
    0,
  );

  return (
    <DailyContentsSecondaryWindowPresenter
      isOpen={isOpen}
      isLogin={isLogin}
      dailyContents={dailyContents}
      playedCount={playedCount}
    />
  );
};
