import React from 'react';
import { PrBanner } from 'js/components/ui/PrBanner';
import footer from '!!raw-loader!js/tag/advertisement/pc/nontop/footer.php';

export type Props = {
  isAdDisplayed: boolean;
  globalFooterBanner?: string;
};

export const FooterBannerPresenter: React.FC<Props> = ({
  isAdDisplayed,
  globalFooterBanner,
}) => {
  const banner = globalFooterBanner ?? footer;
  return isAdDisplayed && banner !== 'none' ? (
    <PrBanner
      size="728x90"
      adInnerHtml={banner}
      style={{ clear: 'both', marginTop: '40px' }}
    />
  ) : null;
};
