import React from 'react';
import { SecondaryContents } from 'js/components/common/layout/Header/SecondaryContents';
import { SecondaryContentsList } from 'js/components/common/layout/Header/SecondaryContentsList';
import { SecondaryContentsWindow } from 'js/components/common/layout/Header/SecondaryContentsWindow';
import { Contents } from 'js/components/common/layout/EtcContents/OtherContents';

export type Props = {
  isOpen: boolean;
  contents: Contents;
};

export const OtherContentsPresenter: React.FC<Props> = ({
  isOpen,
  contents,
}) => (
  <SecondaryContentsWindow isOpen={isOpen}>
    <div className="secondary-contents__wrapper">
      <SecondaryContents heading="その他のコンテンツ">
        <SecondaryContentsList list={contents} column={2} />
      </SecondaryContents>
    </div>
  </SecondaryContentsWindow>
);
