import React from 'react';
import {
  WideGlobalFooterPresenter,
  Props as PresenterProps,
} from 'js/components/common/layout/Footer/WideGlobalFooter/presenter';

export type Props = PresenterProps;

export const WideGlobalFooter: React.FC<Props> = ({ isLogin }) => (
  <WideGlobalFooterPresenter isLogin={isLogin} />
);
