import React from 'react';
import classNames from 'classnames';
import { PointPattern } from 'js/components/common/Point/PointPattern';
import { AffiliateItemType } from 'js/types/affiliate';

export type Props = Omit<AffiliateItemType, 'createdAt'> & {
  className?: string;
  isBannerOnly?: boolean;
};

export const AffiliateSimpleItemPresenter: React.FC<Props> = ({
  linkUrl,
  imageUrl,
  itemName,
  condition,
  campaignPoint,
  regularPoint,
  unit,
  isGradeUpCount,
  className,
  isBannerOnly,
}) => (
  <li
    className={classNames('p_affiliate-simple__item', className, {
      'is-banner-only': isBannerOnly,
    })}
  >
    <a
      className="p_affiliate-simple__link c_reset c_blocklink c_hover-pop"
      href={linkUrl}
      title={condition}
    >
      <figure className="p_affiliate-simple__image-wrapper">
        <img
          className="p_affiliate-simple__image"
          src={imageUrl}
          alt={itemName}
          width="200"
          height="200"
          loading="lazy"
        />
      </figure>
      {!isBannerOnly && (
        <>
          <div className="p_affiliate-simple__name-wrapper">
            <p className="p_affiliate-simple__name">{itemName}</p>
          </div>
          {regularPoint || isGradeUpCount ? (
            <PointPattern
              baseClassName="p_affiliate-simple"
              afterSize="medium"
              {...{ campaignPoint, regularPoint, unit, isGradeUpCount }}
            />
          ) : (
            condition && (
              <p className="p_affiliate-simple__condition">{condition}</p>
            )
          )}
        </>
      )}
    </a>
  </li>
);
