import React from 'react';
import { SnsLink } from 'js/components/common/layout/Footer/SnsLink';
import { animateScroll } from 'react-scroll';
import { WideGlobalFooterLinks } from 'js/components/common/layout/Footer/WideGlobalFooterLinks';
import { Company } from 'js/const/company';

export type Props = {
  isLogin: boolean | undefined;
};

export const WideGlobalFooterPresenter: React.FC<Props> = ({ isLogin }) => (
  <footer className="wide-global-footer">
    <div className="wide-global-footer__container clearfix">
      <div className="wide-global-footer__contents-area clearfix">
        <SnsLink />
        <WideGlobalFooterLinks {...{ isLogin }} />
      </div>
      <p className="wide-global-footer__page-top">
        <button
          className="wide-global-footer__page-top-link"
          onClick={() => animateScroll.scrollToTop()}
        >
          トップへ
        </button>
      </p>
      <ul className="wide-global-footer__safe-use-list">
        <li className="wide-global-footer__safe-use-item">
          <a
            className="wide-global-footer__safe-use-link"
            href="/illegal_policy/"
            target="_top"
          >
            <img
              className="wide-global-footer__image"
              src="/global/img/safe_use/bc_illegal_policy.png"
              alt="不正行為への取り組み"
              width="174"
              height="54"
              loading="lazy"
            />
          </a>
        </li>
        <li className="wide-global-footer__safe-use-item">
          <a
            className="wide-global-footer__safe-use-link"
            href="http://www.jipc.jp/%e3%81%82%e3%82%93%e3%81%97%e3%82%93%e3%83%9d%e3%82%a4%e3%83%b3%e3%83%88/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="wide-global-footer__image"
              src="/global/img/safe_use/bc_jipc.jpg"
              alt="JIPCポイントサービス"
              width="278"
              height="116"
              loading="lazy"
            />
          </a>
        </li>
        <li className="wide-global-footer__safe-use-item">
          <a
            className="wide-global-footer__safe-use-link"
            href="https://privacymark.jp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="wide-global-footer__image"
              src="/global/img/privacy_mark.png"
              alt="大切にしますプライバシー"
              width="70"
              height="70"
              loading="lazy"
            />
          </a>
        </li>
      </ul>
      <ul className="wide-global-footer__basic_list">
        <li className="wide-global-footer__basic_item">
          <a
            className="wide-global-footer__basic_link"
            href="/help/"
            target="_top"
          >
            ヘルプ
          </a>
        </li>
        <li className="wide-global-footer__basic_item">
          <a
            className="wide-global-footer__basic_link"
            href="/sitemap/"
            target="_top"
          >
            サイトマップ
          </a>
        </li>
        <li className="wide-global-footer__basic_item">
          {/* TODO: TOPページがNext.js化されたらnext/linkを使う */}
          <a
            href="/terms/"
            className="wide-global-footer__basic_link"
            target="_top"
          >
            利用規約
          </a>
        </li>
        <li className="wide-global-footer__basic_item">
          <a className="wide-global-footer__basic_link" href="/privacy/">
            プライバシーポリシー
          </a>
        </li>
        <li className="wide-global-footer__basic_item">
          <a
            className="wide-global-footer__basic_link"
            href="/requirements/"
            target="_top"
          >
            動作環境
          </a>
        </li>
        <li className="wide-global-footer__basic_item">
          <a
            className="wide-global-footer__basic_link"
            href={Company.URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            運営会社
          </a>
        </li>
        <li className="wide-global-footer__basic_item">
          <a
            className="wide-global-footer__basic_link"
            href="https://ecnavi.jp/research/privacy/"
          >
            アンケートプライバシーポリシー
          </a>
        </li>
        <li className="wide-global-footer__basic_item">
          <a
            className="wide-global-footer__basic_link"
            href="https://digitalio.jp/service/ecnavi/"
            target="_blank"
            rel="noopener noreferrer"
          >
            広告掲載について(法人様)
          </a>
        </li>
        <li className="wide-global-footer__basic_item">
          <a
            className="wide-global-footer__basic_link"
            href="https://digitalio.jp/service/ecnavi/#:~:text=%E3%82%A2%E3%83%B3%E3%82%B1%E3%83%BC%E3%83%88%E8%AA%BF%E6%9F%BB%E3%83%97%E3%83%A9%E3%83%B3"
            target="_blank"
            rel="noopener noreferrer"
          >
            アンケート調査ご希望の方(法人様)
          </a>
        </li>
      </ul>
      <address className="wide-global-footer__copyright c_font_verdana">
        Copyright&copy; {Company.NAME}, inc. All Rights Reserved.
      </address>
    </div>
  </footer>
);
