import { useRequest } from 'js/hooks/useRequest';

export interface GlobalHeaderNotificationResponse {
  count?: number;
  new_release?: boolean;
}

export const useNotifications = () => {
  /**
   * countとnew_releaseが含まれるオブジェクトの配列を返す
   * 配列の順序は/lib/common/config/global_header/primary_menu.ymlに依存する
   */
  const { data } = useRequest<GlobalHeaderNotificationResponse[]>(
    '/global/header/api/notification/',
  );

  /**
   * 現在「アンケート」と「その他」のcountしか使用していない
   * APIではそれ以外の値も取得しているがこの関数では必要なもの以外は返さないようにする
   */
  return {
    notifications: {
      research: { count: data?.[2].count ?? 0 },
      etc: { count: data?.[6].count ?? 0 },
    },
  };
};
