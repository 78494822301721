import React from 'react';
import classNames from 'classnames';

export type Props = {
  children: React.ReactNode;
  isOpen: boolean;
};

export const SecondaryContentsWindowPresenter: React.FC<Props> = ({
  children,
  isOpen,
}) => (
  <div
    data-testid="TestId__SECONDARY_CONTENTS_WINDOW"
    className={classNames('secondary-contents', { 'is-open': isOpen })}
  >
    {children}
  </div>
);
