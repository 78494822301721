import { useUserInfo } from 'js/hooks/useUserInfo';
import { UserStatusType } from 'js/components/common/layout/Header/WideGlobalHeader/presenter';

export const useUserStatus = () => {
  const { userInfo } = useUserInfo();
  const userStatus: UserStatusType | undefined = userInfo && {
    gradeLabel: userInfo.grade.name,
    nickname: userInfo.nickname,
    totalPoint: userInfo.totalPoint,
  };
  return { userStatus };
};
