import React from 'react';
import {
  SecondaryContentsWindowPresenter,
  Props as PresenterProps,
} from 'js/components/common/layout/Header/SecondaryContentsWindow/presenter';

export type Props = PresenterProps;

export const SecondaryContentsWindow: React.FC<Props> = (props) => (
  <SecondaryContentsWindowPresenter {...props} />
);
