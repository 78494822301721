import React from 'react';
import { useDailyContentsCount } from 'js/components/common/layout/Header/WideGlobalHeader/hooks/useDailyContentsCount';
import { useCampaignCount } from 'js/components/common/layout/Header/WideGlobalHeader/hooks/useCampaignCount';
import { useNotifications } from 'js/components/common/layout/Header/WideGlobalHeader/hooks/useNotifications';
import { DailyContentsSecondaryWindow } from 'js/components/common/layout/DailyContents/DailyContentsSecondaryWindow';
import { OtherContents } from 'js/components/common/layout/EtcContents/OtherContents';
import { GlobalMenuItemType } from 'js/components/common/layout/Header/WideGlobalMenu/type';

export const useGlobalMenuList = () => {
  const { dailyContentsCount } = useDailyContentsCount();
  const { campaignCount } = useCampaignCount();
  const { notifications } = useNotifications();
  const globalMenuList: GlobalMenuItemType[] = [
    {
      name: 'ホーム',
      className: 'type-home',
      href: '/',
    },
    {
      name: '毎日貯まる',
      className: 'type-daily',
      secondaryContents: <DailyContentsSecondaryWindow isOpen={false} />,
      count: dailyContentsCount,
    },
    {
      name: 'ショップ&サービス',
      className: 'type-shop-service',
      href: '/search/?sort=Newer',
    },
    {
      name: 'キャンペーン',
      className: 'type-campaign',
      href: '/campaign/',
      count: campaignCount,
    },
    {
      name: 'アンケート',
      className: 'type-research',
      href: '/research/',
      count: notifications.research.count,
    },
    {
      name: 'モニター',
      className: 'type-monitor',
      href: '/monitor/',
    },
    {
      name: 'お友達紹介',
      className: 'type-invite',
      href: '/contents/invite/',
    },
    {
      name: 'その他',
      className: 'type-etc',
      secondaryContents: <OtherContents isOpen={false} />,
      count: notifications.etc.count,
    },
  ];

  return { globalMenuList };
};
