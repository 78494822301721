import React from 'react';
import {
  MyMenuWithFavoriteWindowPresenter,
  Props as PresenterProps,
} from 'js/components/common/layout/MyMenu/MyMenuWithFavoriteWindow/presenter';

export type Props = PresenterProps;

export const MyMenuWithFavoriteWindow: React.FC<Props> = ({
  isShow,
  myMenuPoint,
  shortcuts,
  favorites,
}) => (
  <MyMenuWithFavoriteWindowPresenter
    isShow={isShow}
    myMenuPoint={myMenuPoint}
    shortcuts={shortcuts}
    favorites={favorites}
  />
);
