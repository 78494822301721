import React from 'react';
import {
  SecondaryContentsPresenter,
  Props as PresenterProps,
} from 'js/components/common/layout/Header/SecondaryContents/presenter';

export type Props = PresenterProps;

export const SecondaryContents: React.FC<Props> = ({ heading, children }) => (
  <SecondaryContentsPresenter heading={heading}>
    {children}
  </SecondaryContentsPresenter>
);
