import React from 'react';
import classNames from 'classnames';
import {
  Props as MyMenuPointProps,
  MyMenuPoint,
} from 'js/components/common/layout/MyMenu/MyMenuPoint';
import { MyMenuPointButton } from 'js/components/common/layout/MyMenu/MyMenuPointButton';
import { MyMenuShortcut } from 'js/components/common/layout/MyMenu/MyMenuShortcut';
import { Props as MyMenuShortcutItemProps } from 'js/components/common/layout/MyMenu/MyMenuShortcutItem';
import { Props as AffiliateSimpleItemProps } from 'js/components/common/Affiliate/AffiliateSimpleItem';
import { MyMenuFavorite } from 'js/components/common/layout/MyMenu/MyMenuFavorite';

export type Props = {
  isShow: boolean;
  myMenuPoint: MyMenuPointProps;
  shortcuts: MyMenuShortcutItemProps[];
  favorites: AffiliateSimpleItemProps[];
};

export const MyMenuWithFavoriteWindowPresenter: React.FC<Props> = ({
  isShow,
  myMenuPoint,
  shortcuts,
  favorites,
}) => (
  <div
    className={classNames('my-menu__window', 'device-pc', {
      'is-open': isShow,
    })}
  >
    <div className="my-menu__unit">
      <MyMenuPoint {...myMenuPoint} isVisible={isShow} />
      <MyMenuPointButton />
    </div>
    <div className="my-menu__unit">
      <MyMenuShortcut list={shortcuts} />
    </div>
    <MyMenuFavorite list={favorites} />
  </div>
);
