import React from 'react';
import classNames from 'classnames';
import { SecondaryContentsWindow } from 'js/components/common/layout/Header/SecondaryContentsWindow';
import { DailyContentsTitle } from 'js/components/common/layout/DailyContents/DailyContentsTitle';
import {
  Props as DailyContentsListProps,
  DailyContentsList,
} from 'js/components/common/layout/DailyContents/DailyContentsList';
import { DailyContentsSkeletonList } from 'js/components/common/layout/DailyContents/DailyContentsSkeletonList';
import { DAILY_CONTENTS_COUNT_PC } from 'js/components/common/layout/DailyContents/DailyContentsSkeletonList/const';
import { RankingPickup } from 'js/components/common/layout/RankingPickup';
import { PC_RANKING_PICKUP_CATEGORIES } from 'js/const/ranking';
import { ColumnType } from 'js/types/gridList';
import {
  PC_BASE_ROW,
  PC_MAX_COLUMN,
  PC_MIN_COLUMN,
} from 'js/components/common/layout/DailyContents/const';

export type Props = {
  isOpen: boolean;
  isLogin: boolean | undefined;
  dailyContents: DailyContentsListProps['list'];
  playedCount: number;
};

export const DailyContentsSecondaryWindowPresenter: React.FC<Props> = ({
  isOpen,
  isLogin,
  dailyContents,
  playedCount,
}) => {
  const column = Math.max(
    PC_MIN_COLUMN,
    Math.min(
      PC_MAX_COLUMN,
      Math.ceil(
        (dailyContents.length || DAILY_CONTENTS_COUNT_PC) / PC_BASE_ROW,
      ),
    ),
  ) as ColumnType;

  return (
    <SecondaryContentsWindow {...{ isOpen }}>
      <div className="daily-contents-window">
        <div
          className={classNames(
            'daily-contents-window__wrapper',
            `column-${column}`,
          )}
        >
          <DailyContentsTitle
            playedCount={playedCount}
            contentsCount={dailyContents.length}
            isLogin={isLogin}
          />
          {!!dailyContents.length ? (
            <DailyContentsList list={dailyContents} column={column} />
          ) : (
            <DailyContentsSkeletonList
              count={DAILY_CONTENTS_COUNT_PC}
              column={column}
            />
          )}
        </div>
        <RankingPickup
          categories={PC_RANKING_PICKUP_CATEGORIES}
          isShow={isOpen}
        />
      </div>
    </SecondaryContentsWindow>
  );
};
