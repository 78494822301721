import { useRequest } from 'js/hooks/useRequest';

export interface CampaignCountPcResponse {
  device: string;
  ended: number;
  entered: number;
  isSuccess: boolean;
  nearEnd: number;
  newer: number;
  notEntered: number;
}

export const useCampaignCount = () => {
  // React単体とNext.jsの2通りから呼び出される。Next.jsの場合ビルド時にdocumentはないので存在チェックをする必要がある
  const timestamp =
    typeof document !== 'undefined'
      ? document.querySelector('html')?.dataset.timestamp
      : undefined;
  const { data } = useRequest<CampaignCountPcResponse>(
    timestamp
      ? [
          '/api/campaign/count/pc/',
          {
            t: timestamp,
          },
        ]
      : null,
  );
  const campaignCount = data?.isSuccess && data?.newer ? data.newer : 0;
  return { campaignCount };
};
