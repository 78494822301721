import React from 'react';
import {
  FooterBannerPresenter,
  Props as PresenterProps,
} from 'js/components/common/layout/Banner/FooterBanner/presenter';

export type Props = PresenterProps;

export const FooterBanner: React.FC<Props> = ({
  isAdDisplayed,
  globalFooterBanner,
}) => (
  <FooterBannerPresenter
    isAdDisplayed={isAdDisplayed}
    globalFooterBanner={globalFooterBanner}
  />
);
