import React, { useState } from 'react';
import {
  WideMyMenuPresenter,
  Props as PresenterProps,
} from 'js/components/common/layout/MyMenu/WideMyMenu/presenter';
import { useFavoriteItems } from 'js/hooks/useFavoriteItems';

export type Props = Omit<PresenterProps, 'setIsReady' | 'favoriteItems'>;

export const WideMyMenu: React.FC<Props> = ({ myMenuPoint, shortcuts }) => {
  const [isReady, setIsReady] = useState(false);
  const { favoriteItems } = useFavoriteItems({
    isReady,
  });

  return (
    <WideMyMenuPresenter
      myMenuPoint={myMenuPoint}
      shortcuts={shortcuts}
      favoriteItems={favoriteItems}
      setIsReady={setIsReady}
    />
  );
};
