import React from 'react';
import {
  AffiliateSimpleItemPresenter,
  Props as PresenterProps,
} from 'js/components/common/Affiliate/AffiliateSimpleItem/presenter';

export type Props = PresenterProps;

export const AffiliateSimpleItem: React.FC<Props> = ({
  linkUrl,
  imageUrl,
  itemName,
  condition,
  campaignPoint,
  regularPoint,
  unit,
  isGradeUpCount,
  className,
  isBannerOnly,
}) => (
  <AffiliateSimpleItemPresenter
    linkUrl={linkUrl}
    imageUrl={imageUrl}
    itemName={itemName}
    condition={condition}
    campaignPoint={campaignPoint}
    regularPoint={regularPoint}
    unit={unit}
    isGradeUpCount={isGradeUpCount}
    className={className}
    isBannerOnly={isBannerOnly}
  />
);
