import React from 'react';
import classNames from 'classnames';
import { Badge } from 'js/components/ui/Notification/Badge';
import { useToggleMenu } from 'js/hooks/useToggleMenu';
import { GlobalMenuItemType } from 'js/components/common/layout/Header/WideGlobalMenu/type';

export type Props = {
  list: GlobalMenuItemType[];
};

export const WideGlobalMenuPresenter: React.FC<Props> = ({ list }) => {
  const globalMenuItemClassName = 'global-menu__item';
  const { isOpen, toggleMenu } = useToggleMenu(globalMenuItemClassName);

  return (
    <nav className="global-menu">
      <ul className="global-menu__list">
        {list.map((item) => (
          <li
            className={classNames(globalMenuItemClassName, item.className)}
            key={item.name}
          >
            {item.href ? (
              <a
                className={classNames('global-menu__link', 'c_reset')}
                href={item.href}
              >
                {item.name}
                {!!item.count && (
                  <Badge className="global-menu__count" count={item.count} />
                )}
              </a>
            ) : (
              <>
                <button
                  className={classNames('global-menu__link', 'c_reset', {
                    'is-open': isOpen(item.name),
                  })}
                  onClick={() => toggleMenu(item.name)}
                >
                  {item.name}
                  {!!item.count && (
                    <Badge className="global-menu__count" count={item.count} />
                  )}
                </button>
                {item.secondaryContents &&
                  React.cloneElement(item.secondaryContents, {
                    ...{ isOpen: isOpen(item.name) },
                  })}
              </>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};
