import React from 'react';
import {
  HeaderBannerPresenter,
  Props as PresenterProps,
} from 'js/components/common/layout/Banner/HeaderBanner/presenter';

type Props = PresenterProps;

export const HeaderBanner: React.FC<Props> = ({
  isAdDisplayed,
  isSimpleHeader,
  globalHeaderBanner,
}) => (
  <HeaderBannerPresenter
    isAdDisplayed={isAdDisplayed}
    isSimpleHeader={isSimpleHeader}
    globalHeaderBanner={globalHeaderBanner}
  />
);
